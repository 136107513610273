import React from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { StaticImage } from "gatsby-plugin-image"
import './navbar.css'

const NavBar = () => (
  <Navbar collapseOnSelect expand="lg" className="nav_background">
    <Navbar.Brand href="/" className="logo" aria-label="Centrum weterynaryjne Amicus">
      <StaticImage src="../../../static/logo-amicus.png" alt="Amicus"/>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="ml-auto">
        <Nav.Link href="/" className="nav_link_color">Strona główna</Nav.Link>
        <Nav.Link href="/o-nas" className="nav_link_color">O nas</Nav.Link>
        <NavDropdown title="Usługi" id="collasible-nav-dropdown" className="nav_link_color">
          <NavDropdown.Item href="/uslugi/okulistyka">Okulistyka</NavDropdown.Item>
          <NavDropdown.Item href="/uslugi/onkologia">Onkologia</NavDropdown.Item>
          <NavDropdown.Item href="/uslugi/kardiologia">Kardiologia</NavDropdown.Item>
          <NavDropdown.Item href="/uslugi/internistyka">Internistyka</NavDropdown.Item>
          <NavDropdown.Item href="/uslugi/chirurgia">Chirurgia</NavDropdown.Item>
        </NavDropdown>
        <Nav.Link href="/przed-wizyta" className="nav_link_color">Przed wizytą</Nav.Link>
        <Nav.Link href="/kontakt" className="nav_link_color">Kontakt</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default NavBar;
