import React from 'react'
import './layout.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';

config.autoAddCss = false;

const Layout = ({ children }) => (
  <div className="amicus_container">
    {children}
  </div>
);

export default Layout;
