import React from 'react'
import { Row, Col } from 'react-bootstrap'
import './footer.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'

const Footer = () => (
    <footer className="footer">
      <div className="contactSection">
        <Row className="pt-3" style={{width: "100%"}}>
          <Col lg={{ span: 4, offset: 2 }} className="col_center">
            <h4>Kontakt</h4>
            <hr/>
            <p>tel. kom. 730 500 250</p>
            <p>e-mail: wetlodz@gmail.com</p>
          </Col>
          <Col lg={{ span: 4}} className="col_center">
            <div className="mt-3">
              <a href="https://www.facebook.com/Amicus-Centrum-weterynaryjne-w-Łodzi-486109201447171/" target="_blank" rel="noreferrer">
                <h4 className="link_fanpage">Polub nas na facebooku!</h4>
                <FontAwesomeIcon icon={faFacebookSquare} size='4x' color='darkblue'/>
              </a>
            </div>
          </Col>
        </Row>
      </div>
      <div className="copyrightSection">
        <p className="copyright">&#169; 2022 Amicus - Centrum weterynaryjne</p>
      </div>
    </footer>
);

export default Footer;
